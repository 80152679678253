<template>
  <phan-quyen-linh-vuc />
</template>

<script>
import PhanQuyenLinhVuc from '@/modules/mot-cua/components/pages/PhanQuyenLinhVuc.vue'

export default {
  name: 'TrangChu',
  components: {
    PhanQuyenLinhVuc,
  },
}
</script>

<style scoped></style>
