<template>
  <b-card>
    <action-btn
      :selected-number="selectedNumber"
      :actions-permission="[checkQuyenThemMoi, checkQuyenXoa]"
      @add-item="addCommom"
      @delete-item="deleteCommom"
      @refresh-page="refreshPage"
    />
    <good-table
      ref="vgTable"
      class="mt-1"
      :class="checkAllQuyen ? '' : 'fixed-column'"
      :columns="getColumns"
      :rows="rows"
      :total="total"
      :is-loading="isLoading"
      :actions-permission="[checkQuyenSua, checkQuyenXoa]"
      @column-filter="columnFilter"
      @selected-item="selectedItem"
      @page-change="pageChange"
      @update-item="updateCommom"
      @delete-item="deleteCommomTable"
    >
      <div
        slot="custom-filter"
        slot-scope="{ props }"
      >
        <span v-if="props.column.field == 'tenLinhVuc'">
          <treeselect
            id="linhVuc"
            ref="tenLinhVuc"
            v-model="payload.linhVucId"
            v-format-v-select
            :default-options="lvCbx"
            placeholder="Chọn lĩnh vực"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            class="vtreeselect-chooser"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            :load-options="onQueryChange"
            :async="true"
            :clear-on-select="true"
            @select="$refs.tenLinhVuc.resetSearchQuery()"
            @input="columnFilterTable()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
        </span>
        <span v-else-if="props.column.field == 'tenNhomNguoiDung'">
          <treeselect
            id="linhVuc"
            ref="tenNhomNguoiDung"
            v-model="payload.nhomNguoiDungId"
            v-format-v-select
            :default-options="npCbx"
            placeholder="Chọn nhóm người dùng"
            :limit="0"
            multiple
            :limit-text="(count) => ` + ${count} lựa chọn`"
            no-options-text="Không có dữ liệu"
            no-results-text="Không có dữ liệu"
            loading-text="Đang tìm kiếm"
            class="vtreeselect-chooser"
            :normalizer="normalizer"
            :match-keys="['label', 'label2']"
            :load-options="onChange"
            :async="true"
            :clear-on-select="true"
            @select="$refs.tenNhomNguoiDung.resetSearchQuery()"
            @input="columnFilterTable()"
          >
            <label
              slot="option-label"
              slot-scope="{ node, shouldShowCount, count, labelClassName }"
              :class="labelClassName"
              :title="node.label"
            >
              {{ node.label }}
            </label>
          </treeselect>
        </span>
      </div>
    </good-table>
    <common-modal
      ref="commonModal"
      :title="title"
      :size="size"
      @handle-ok="handleOk"
    >
      <component
        :is="componentName === 'XoaForm' ? '' : componentName"
        :data-form="dataForm"
      />
    </common-modal>
  </b-card>
</template>
<script>
import { mapGetters } from 'vuex'
import {
  BCard,
} from 'bootstrap-vue'
import addCssSemantic from '@/mixins/mixins'
import ActionBtn from '@/modules/mot-cua/components/common/ActionBtn.vue'
import CommonModal from '@/modules/mot-cua/components/modal/CommonModal.vue'
import GoodTable from '@/components/GoodTable.vue'
import END_POINTS from '@/api/endpoints'
import PhanQuyenLinhVuc from '@/modules/mot-cua/components/form/PhanQuyenLinhVuc.vue'
import XoaForm from '@/modules/mot-cua/components/form/XoaForm.vue'
import { removeDiacritical } from '@/utils/index'
import { PERMISSION_NAME } from '@/constants/constants'
import { hasPermission } from '@/utils/permission-utils'
import _debounce from 'lodash/debounce'

export default {
  components: {
    BCard,
    ActionBtn,
    CommonModal,
    GoodTable,
    PhanQuyenLinhVuc,
    XoaForm,
  },
  mixins: [addCssSemantic],
  data() {
    return {
      title: 'Thêm mới phân quyền lĩnh vực - nhóm người dùng',
      columns: [
        {
          label: 'STT',
          thClass: 'text-center custom-style',
          tdClass: 'text-center',
          width: '50px',
          field: 'STT',
          sortable: false,
        },
        {
          label: 'Tên nhóm người dùng',
          field: 'tenNhomNguoiDung',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Tên lĩnh vực',
          field: 'tenLinhVuc',
          thClass: 'text-center',
          filterOptions: {
            enabled: true,
            placeholder: 'Tìm kiếm',
            filterValue: null,
          },
        },
        {
          label: 'Thao tác',
          field: 'actions',
          width: '180px',
          tdClass: 'text-center vertical-align-middle',
          thClass: 'text-center',
        },
      ],
      rows: [],
      selectedItems: [],
      lvCbx: [],
      npCbx: [],
      componentName: null,
      total: 0,
      selectedNumber: 0,
      payload: {
        linhVucId: [],
        nhomNguoiDungId: [],
        pageNumber: 1,
        pageSize: 10,
      },
      dataForm: {
        linhVucId: null,
        nhomNguoiDungId: null,
      },
      isLoading: false,
      size: null,
    }
  },
  computed: {
    ...mapGetters({
      permission: 'common/permissions',
    }),
    checkQuyenThemMoi() {
      return hasPermission([PERMISSION_NAME.PHAN_QUYEN_LINH_VUC.THEM_MOI])
    },
    checkQuyenSua() {
      return hasPermission([PERMISSION_NAME.PHAN_QUYEN_LINH_VUC.SUA])
    },
    checkQuyenXoa() {
      return hasPermission([PERMISSION_NAME.PHAN_QUYEN_LINH_VUC.XOA])
    },
    checkAllQuyen() {
      return [this.checkQuyenSua, this.checkQuyenXoa].every(item => item === false)
    },
    getColumns() {
      return this.checkAllQuyen ? this.columns.filter(item => item.field !== 'actions') : this.columns
    },
  },
  created() {
    this.getDataPhanQuyenLinhVuc()
    this.getDataLinhVucCbx()
    this.getDataNhomNguoiDung()
  },
  methods: {
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
      }
    },
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.lvCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    onChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchChange: _debounce(async function (query, callback) {
      const response = this.npCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    addCommom() {
      this.title = 'Thêm mới phân quyền lĩnh vực - nhóm người dùng'
      this.componentName = 'PhanQuyenLinhVuc'
      this.size = 'lg'
      this.dataForm = {
        linhVucId: null,
        nhomNguoiDungId: null,
      }
      this.$refs.commonModal.showModal()
    },
    selectedItem(data) {
      this.selectedItems = data
      this.selectedNumber = data.length
    },
    getDataLinhVucCbx() {
      this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.lvCbx = res.data.data
        }
      })
    },
    getDataNhomNguoiDung() {
      this.$axios.get(END_POINTS.CHUNG.NHOM_NGUOI_DUNG).then(res => {
        if (res.data?.succeeded) {
          this.npCbx = res.data.data
        }
      })
    },
    getDataPhanQuyenLinhVuc() {
      this.isLoading = false
      try {
        this.$axios.post(END_POINTS.LINH_VUC_NHOM_NGUOI_DUNG.DS, this.payload, false).then(res => {
          if (res.data?.succeeded) {
            this.rows = res.data.data
            this.total = res.data.totalCount
          }
        })
      } finally {
        setTimeout(() => {
          this.isLoading = true
        }, 2000)
      }
    },
    refreshPage() {
      this.payload = {
        linhVucId: [],
        nhomNguoiDungId: [],
        pageNumber: 1,
        pageSize: 10,
      }
      this.$refs.vgTable.$refs.vbTables.reset()
      this.getDataPhanQuyenLinhVuc()
    },
    handleOk() {
      if (this.componentName === 'XoaForm') {
        const payload = {
          data: this.selectedItems.map(item => item.id),
        }
        this.$axios.delete(END_POINTS.LINH_VUC_NHOM_NGUOI_DUNG.XOA, payload).then(res => {
          if (res.data?.succeeded) {
            if (this.selectedItems.length >= this.rows.length && this.payload.pageNumber > 1) {
              this.payload.pageNumber -= 1
              this.$refs.vgTable.resetCurrentPage(1)
            }
            this.getDataPhanQuyenLinhVuc()
            this.$refs.commonModal.hideModal()
          }
        })
      } else if (this.componentName === 'PhanQuyenLinhVuc') {
        // eslint-disable-next-line prefer-const
        let para = []
        this.dataForm.linhVucId.forEach(item => {
          if (Array.isArray(this.dataForm.nhomNguoiDungId)) {
            para.push(...this.dataForm.nhomNguoiDungId.map(ele => ({
              linhVucId: item,
              nhomNguoiDungId: ele,
            })))
          } else {
            para.push({
              linhVucId: item,
              nhomNguoiDungId: this.dataForm.nhomNguoiDungId,
            })
          }
        })
        if (this.dataForm?.id) {
          this.$axios.put(END_POINTS.LINH_VUC_NHOM_NGUOI_DUNG.SUA, para).then(res => {
            if (res.data?.succeeded) {
              this.getDataPhanQuyenLinhVuc()
              this.$refs.commonModal.hideModal()
            }
          })
        } else {
          this.$axios.post(END_POINTS.LINH_VUC_NHOM_NGUOI_DUNG.THEM, para).then(res => {
            if (res.data?.succeeded) {
              this.getDataPhanQuyenLinhVuc()
              this.$refs.commonModal.hideModal()
            }
          })
        }
      }
    },
    updateCommom(data) {
      this.title = 'Cập nhật phân quyền lĩnh vực - nhóm người dùng'
      this.componentName = 'PhanQuyenLinhVuc'
      this.size = 'lg'
      const param = {
        linhVucId: [],
        nhomNguoiDungId: [{ id: data.nhomNguoiDungId }],
        pageNumber: 1,
        pageSize: this.total,
      }
      this.$axios.post(END_POINTS.LINH_VUC_NHOM_NGUOI_DUNG.DS, param, false).then(res => {
        if (res.data?.succeeded) {
          this.dataForm = {
            id: data.id,
            linhVucId: res.data?.data.map(item => item.linhVucId),
            nhomNguoiDungId: data.nhomNguoiDungId,
          }
          this.$refs.commonModal.showModal()
        }
      })
    },
    deleteCommom() {
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa phân quyền lĩnh vực - nhóm người dùng?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.$refs.commonModal.showModal()
    },
    deleteCommomTable(data) {
      this.title = 'Xác nhận'
      this.dataForm = 'Bạn có xác nhận xóa phân quyền lĩnh vực - nhóm người dùng?'
      this.componentName = 'XoaForm'
      this.size = 'md'
      this.selectedItems = [data]
      this.$refs.commonModal.showModal()
    },
    pageChange() {
      const data = this.$refs.vgTable.getCurrentPage()
      this.payload = {
        ...this.payload,
        pageNumber: data.pageNumber,
        pageSize: data.pageSize,
      }
      this.$axios.post(END_POINTS.LINH_VUC_NHOM_NGUOI_DUNG.DS, this.payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
        }
      })
    },
    columnFilter(data) {
      const payload = {
        ...data,
        linhVucId: this.payload.linhVucId && this.payload.linhVucId.length > 0 ? this.payload.linhVucId.map(item => ({ id: item })) : [],
        nhomNguoiDungId: this.payload.nhomNguoiDungId && this.payload.nhomNguoiDungId.length > 0 ? this.payload.nhomNguoiDungId.map(item => ({ id: item })) : [],
        pageNumber: 1,
        pageSize: this.payload.pageSize,
      }
      this.$axios.post(END_POINTS.LINH_VUC_NHOM_NGUOI_DUNG.DS, payload, false).then(res => {
        if (res.data?.succeeded) {
          this.rows = res.data.data
          this.total = res.data.totalCount
          this.$refs.vgTable.resetCurrentPage(1)
        }
      })
    },
    columnFilterTable() {
      this.$nextTick(() => {
        this.$refs.vgTable.onColumnFilter()
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
  },
}
</script>
