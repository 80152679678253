<template>
  <div>
    <b-form-group
      id="input-group-1"
      label="Tên vai trò"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="nhomNguoiDung"
        :rules="{ required: true }"
        name="Vai trò"
      >
        <treeselect
          id="linhVuc"
          ref="tenNhomNguoiDung"
          v-model="dataForm.nhomNguoiDungId"
          v-format-v-select
          :default-options="npCbx"
          placeholder="Chọn nhóm người dùng"
          class="autoFocus"
          :limit="0"
          :multiple="dataForm.id ? false : true"
          :disabled="dataForm.id ? true : false"
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          loading-text="Đang tìm kiếm"
          :normalizer="normalizer"
          :match-keys="['label', 'label2']"
          :load-options="onChange"
          :async="true"
          :clear-on-select="true"
          @select="$refs.tenNhomNguoiDung.resetSearchQuery()"
        >
          <label
            slot="option-label"
            slot-scope="{ node, shouldShowCount, count, labelClassName }"
            :class="labelClassName"
            :title="node.label"
          >
            {{ node.label }}
          </label>
        </treeselect>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
    <b-form-group
      id="input-group-1"
      label="Tên lĩnh vực"
      label-for="input-1"
      class="required"
    >
      <validation-provider
        v-slot="{ errors }"
        key="tenLinhVuc"
        :rules="{ required: true }"
        name="Tên lĩnh vực"
      >
        <treeselect
          id="linhVuc"
          ref="tenLinhVuc"
          v-model="dataForm.linhVucId"
          v-format-v-select
          :default-options="lvCbx"
          placeholder="Chọn lĩnh vực"
          :limit="0"
          multiple
          :limit-text="(count) => ` + ${count} lựa chọn`"
          no-options-text="Không có dữ liệu"
          no-results-text="Không có dữ liệu"
          loading-text="Đang tìm kiếm"
          :normalizer="normalizer"
          :match-keys="['label', 'label2']"
          :load-options="onQueryChange"
          :async="true"
          :clear-on-select="true"
          @select="$refs.tenLinhVuc.resetSearchQuery()"
        >
          <label
            slot="option-label"
            slot-scope="{ node, shouldShowCount, count, labelClassName }"
            :class="labelClassName"
            :title="node.label"
          >
            {{ node.label }}
          </label>
        </treeselect>
        <span class="label-noti-validate">{{ errors[0] }}</span>
      </validation-provider>
    </b-form-group>
  </div>
</template>
<script>
import END_POINTS from '@/api/endpoints'
import {
  BFormGroup,
} from 'bootstrap-vue'
import { extend } from 'vee-validate'
import { required } from 'vee-validate/dist/rules'
import { removeDiacritical } from '@/utils/index'
import _debounce from 'lodash/debounce'
// import $ from 'jquery'

extend('required', {
  ...required,
  message: 'Vui lòng nhập thông tin',
})

export default {
  components: {
    BFormGroup,
  },
  props: {
    dataForm: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      // autoFocus: true,
      lvCbx: [],
      npCbx: [],
    }
  },
  created() {
    // $(document).ready(() => {
    //   // you may need to delete all tabindexes first.
    //   $('.autoFocus').focus()
    // })
  },
  mounted() {
    this.getDataLinhVucCbx()
    this.getDataNhomNguoiDung()
    // eslint-disable-next-line func-names
    // this.$nextTick(function () {
    //   this.$refs.tenNhomNguoiDung.$el.querySelector('input').focus()
    // document.getElementsByClassName('vue-treeselect__input')[0].focus()
    // $('.vue-treeselect__input').focus()
    // eslint-disable-next-line no-unused-expressions
    // })
    // })
  },
  methods: {
    // onFocus() {
    //   this.$el.querySelector('linhVuc').focus()
    // },
    normalizer(node) {
      return {
        id: node.id,
        label: node.name,
        label2: removeDiacritical(node.tenDonVi),
      }
    },
    onQueryChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchQueryChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchQueryChange: _debounce(async function (query, callback) {
      const response = this.lvCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    onChange({ action, searchQuery, callback }) {
      if (action === 'ASYNC_SEARCH') {
        this.handleSearchChange(searchQuery, callback)
      }
    },
    // eslint-disable-next-line func-names
    handleSearchChange: _debounce(async function (query, callback) {
      const response = this.npCbx.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(query)) >= 0)
      callback(null, response)
    }, 100),
    getDataLinhVucCbx() {
      this.$axios.get(END_POINTS.LINH_VUC.COMBOBOX).then(res => {
        if (res.data?.succeeded) {
          this.lvCbx = res.data.data
        }
      })
    },
    getDataNhomNguoiDung() {
      let url = null
      if (this.dataForm.id) {
        url = END_POINTS.CHUNG.NHOM_NGUOI_DUNG
      } else {
        url = END_POINTS.LINH_VUC_NHOM_NGUOI_DUNG.DS_VAI_TRO
      }
      this.$axios.get(url).then(res => {
        if (res.data?.succeeded) {
          this.npCbx = res.data.data
        }
      })
    },
    onFilterSelect(options, search) {
      return options.filter(item => removeDiacritical(item.name).indexOf(removeDiacritical(search.trim())) >= 0)
    },
  },
}
</script>
