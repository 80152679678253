var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Tên vai trò","label-for":"input-1"}},[_c('validation-provider',{key:"nhomNguoiDung",attrs:{"rules":{ required: true },"name":"Vai trò"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"tenNhomNguoiDung",staticClass:"autoFocus",attrs:{"id":"linhVuc","default-options":_vm.npCbx,"placeholder":"Chọn nhóm người dùng","limit":0,"multiple":_vm.dataForm.id ? false : true,"disabled":_vm.dataForm.id ? true : false,"limit-text":function (count) { return (" + " + count + " lựa chọn"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","loading-text":"Đang tìm kiếm","normalizer":_vm.normalizer,"match-keys":['label', 'label2'],"load-options":_vm.onChange,"async":true,"clear-on-select":true},on:{"select":function($event){return _vm.$refs.tenNhomNguoiDung.resetSearchQuery()}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}],null,true),model:{value:(_vm.dataForm.nhomNguoiDungId),callback:function ($$v) {_vm.$set(_vm.dataForm, "nhomNguoiDungId", $$v)},expression:"dataForm.nhomNguoiDungId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1),_c('b-form-group',{staticClass:"required",attrs:{"id":"input-group-1","label":"Tên lĩnh vực","label-for":"input-1"}},[_c('validation-provider',{key:"tenLinhVuc",attrs:{"rules":{ required: true },"name":"Tên lĩnh vực"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('treeselect',{directives:[{name:"format-v-select",rawName:"v-format-v-select"}],ref:"tenLinhVuc",attrs:{"id":"linhVuc","default-options":_vm.lvCbx,"placeholder":"Chọn lĩnh vực","limit":0,"multiple":"","limit-text":function (count) { return (" + " + count + " lựa chọn"); },"no-options-text":"Không có dữ liệu","no-results-text":"Không có dữ liệu","loading-text":"Đang tìm kiếm","normalizer":_vm.normalizer,"match-keys":['label', 'label2'],"load-options":_vm.onQueryChange,"async":true,"clear-on-select":true},on:{"select":function($event){return _vm.$refs.tenLinhVuc.resetSearchQuery()}},scopedSlots:_vm._u([{key:"option-label",fn:function(ref){
var node = ref.node;
var shouldShowCount = ref.shouldShowCount;
var count = ref.count;
var labelClassName = ref.labelClassName;
return _c('label',{class:labelClassName,attrs:{"title":node.label}},[_vm._v(" "+_vm._s(node.label)+" ")])}}],null,true),model:{value:(_vm.dataForm.linhVucId),callback:function ($$v) {_vm.$set(_vm.dataForm, "linhVucId", $$v)},expression:"dataForm.linhVucId"}}),_c('span',{staticClass:"label-noti-validate"},[_vm._v(_vm._s(errors[0]))])]}}])})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }